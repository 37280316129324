import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLotNo } from 'Redux/actions';
import loc from 'Components/languages';
import Modal from 'Components/modal';
import Spinner from 'Components/spinner';
import Pagination from 'Components/pagination';
import LotNoListItem from './LotNoListItem.jsx';
import styles from './ModalLotNo.less';

class ModalLotNo extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 300px)',
				right: 'calc(50% - 300px)',
				maxHeight: '600px',
				padding: 0,
			},
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
	}

	componentDidMount() {
		const { itemCode, isOpen } = this.props;
		if (itemCode && isOpen) {
			this.setState({
				page: 1,
			});
			this.fetchData(itemCode);
		}
	}

	componentDidUpdate(prevProps) {
		const itemCode = this.props.itemCode;
		const prevItemCode = prevProps.itemCode;
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (itemCode && (itemCode !== prevItemCode || isOpen && isOpen !== prevIsOpen)) {
			this.setState({
				page: 1,
			});
			this.fetchData(itemCode);
		}
	}

	fetchData(itemCode) {
		const { page } = this.state;
		if (!itemCode) {
			itemCode = this.props.itemCode;
		}
		if (!itemCode) {
			return;
		}
		const param = {
			payload: {
				item_code: itemCode,
				page,
				is_current_shop: true,
			}
		};
		this.props.getLotNo(param);
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	render() {
		const { isOpen, onToggle, onSelect, lotNoInfo } = this.props;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.lotNo }
				style={ this.modalStyle }
			>
				<div className={ styles.list }>
					{
						lotNoInfo.isFetching ? (
							<Spinner />
						) : (
							<ul className="uk-list uk-list-divider">
								{
									lotNoInfo.data && lotNoInfo.data.data && lotNoInfo.data.data.map((lotNo, index) => (
										<LotNoListItem
											key={ `modal-lot-no-list-item-${index}` }
											lotNo={ lotNo }
											onSelect={ onSelect }
										/>
									))
								}
							</ul>
						)
					}
				</div>

				<Pagination
					data={ lotNoInfo.data }
					onPageChange={ this.onChangePageHandler }
				/>
			</Modal>
		);
	}
}

ModalLotNo.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	itemCode: PropTypes.string.isRequired,
};

export default connect(
	(state) => ({
		lotNoInfo: state.lotNoInfo,
	}),
	(dispatch) => ({
		getLotNo: para => dispatch(getLotNo(para)),
	})
)(ModalLotNo);