import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Set, Map } from 'immutable';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { getServiceItems } from 'Redux/actions';
import loc from 'Components/languages';
import Modal from 'Components/modal';
import Tab from 'Components/tab';
import SectionSelectServiceItems from './SectionSelectServiceItems.jsx';
import SectionFavoriteItems from './SectionFavoriteItems.jsx';
import styles from './ModalSelectItems.less';

class ModalSelectItems extends PureComponent {
	constructor() {
		super();
		this.state = {
			currentTab: 'serviceItems',
		};
		this.tabs = [
			'serviceItems',
			'favoriteItems',
		];
		this.modalStyle = {
			content: {
				padding: 0,
				inset: '70px',
			},
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangeTabHandler = this.onChangeTabHandler.bind(this);
		this.onSelectTransactionItemHandler = this.onSelectTransactionItemHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (isOpen && isOpen !== prevIsOpen) {
			this.fetchData();
		}
	}

	fetchData() {
		this.props.getServiceItems();
	}

	onChangeTabHandler(tab) {
		this.setState({
			currentTab: tab,
		});
	}

	onSelectTransactionItemHandler(transactionItem) {
		const { onSelect, onToggle } = this.props;
		onSelect(transactionItem);
		onToggle();
	}

	render() {
		const { isOpen, isLoading, type, salesOrder, onToggle, onChange, transactionItemCodeSet } = this.props;
		const { currentTab } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ currentTab === 'serviceItems' ? loc.addServiceItems : loc.favoriteItems }
				style={ this.modalStyle }
			>
				<div className={ styles.btnClose } onClick={ onToggle }>
					<FontAwesomeIcon icon={ faChevronLeft } />
				</div>
				<div className={ styles.tab }>
					<Tab
						tabs={ this.tabs }
						current={ currentTab }
						onChange={ this.onChangeTabHandler }
					/>
				</div>
				{
					currentTab === 'serviceItems' && (
						<SectionSelectServiceItems
							isLoading={ isLoading }
							onChange={ onChange }
							transactionItemCodeSet={ transactionItemCodeSet }
						/>
					)
				}
				{
					currentTab === 'favoriteItems' && (
						<SectionFavoriteItems
							isLoading={ isLoading }
							type={ type }
							salesOrder={ salesOrder }
							onSelect={ this.onSelectTransactionItemHandler }
							transactionItemCodeSet={ transactionItemCodeSet }
						/>
					)
				}
			</Modal>
		);
	}
}

ModalSelectItems.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	onToggle: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	transactionItemCodeSet: PropTypes.instanceOf(Set).isRequired,
};

export default connect(
	() => ({}),
	(dispatch) => ({
		getServiceItems: option => dispatch(getServiceItems(option)),
	})
)(ModalSelectItems);
