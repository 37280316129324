import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, Set } from 'immutable';
import { receiveApi } from 'Helpers/helpers';
import Spinner from 'Components/spinner';
import Pagination from 'Components/pagination';
import { getFavoriteItems } from 'Redux/actions';
import * as cons from 'Redux/constants';
import ItemListItem from './ItemListItem.jsx';
import styles from './SectionFavoriteItems.less';

class SectionFavoriteItems extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
			size: 20,
			is_favorite: true,
			param: new Map({
				sort_direction: 'asc',
				sort_column: 'item_name_en',
			}),
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		const setItemToCurrentShopInfo = this.props.setItemToCurrentShopInfo;
		const prevSetItemToCurrentShopInfo = prevProps.setItemToCurrentShopInfo;
		const unsetItemFromCurrentShopInfo = this.props.unsetItemFromCurrentShopInfo;
		const prevUnsetItemFromCurrentShopInfo = prevProps.unsetItemFromCurrentShopInfo;

		if (
			receiveApi(setItemToCurrentShopInfo, prevSetItemToCurrentShopInfo, cons.SET_ITEM_TO_CURRENT_SHOP) ||
			receiveApi(unsetItemFromCurrentShopInfo, prevUnsetItemFromCurrentShopInfo, cons.UNSET_ITEM_FROM_CURRENT_SHOP)
		) {
			this.fetchData();
		}
	}

	fetchData() {
		const { page, size, is_favorite } = this.state;
		const { type, salesOrder } = this.props;
		let param = {
			payload: {
				...this.state.param.toJS(),
				page,
				size,
				is_favorite,
			},
		};
		switch (type) {
		case 'salesOrder':
			param.payload.is_active = true;
			param.payload.is_service_memo = false;
			param.payload.is_stock_check = salesOrder.get('doc_type') !== 'DP1';
			break;
		case 'serviceMemo':
			param.payload.is_active = true;
			param.payload.is_service_memo = true;
			param.payload.is_stock_check = salesOrder.get('doc_type') !== 'DP1';
			break;
		case 'shopOrder':
			break;
		case 'stockTransfer':
			param.payload.is_active = true;
			param.payload.is_stock_check = true;
			param.payload.is_current_shop = true;
			param.payload.is_stock_transfer = true;
			break;
		}
		this.props.getFavoriteItems(param);
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	render() {
		const { favoriteItemsInfo, onSelect } = this.props;
		return (
			<Fragment>
				<div className={ styles.list }>
					<ul className="uk-list uk-list-divider">
						{
							(!favoriteItemsInfo || favoriteItemsInfo.isFetching) ? (
								<div className="uk-margin-top uk-margin-bottom">
									<Spinner />
								</div>
							) : (
								favoriteItemsInfo.data.data && favoriteItemsInfo.data.data.map((item, index) => (
									<ItemListItem
										key={ `section-favorite-items-${index}` }
										item={ item }
										onClick={ onSelect }
										showCurrentShop={ true }
									/>
								))
							)
						}
					</ul>
				</div>
				{
					favoriteItemsInfo && (
						<Pagination
							data={ favoriteItemsInfo.data }
							onPageChange={ this.onChangePageHandler }
						/>
					)
				}
			</Fragment>
		);
	}
}

SectionFavoriteItems.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	onSelect: PropTypes.func.isRequired,
	transactionItemCodeSet: PropTypes.instanceOf(Set).isRequired,
};

export default connect(
	(state) => ({
		favoriteItemsInfo: state.favoriteItemsInfo,
		setItemToCurrentShopInfo: state.setItemToCurrentShopInfo,
		unsetItemFromCurrentShopInfo: state.unsetItemFromCurrentShopInfo,
	}),
	(dispatch) => ({
		getFavoriteItems: para => dispatch(getFavoriteItems(para)),
	})
)(SectionFavoriteItems);